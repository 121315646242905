import React, { useState } from "react";
import axios from "axios";
import "./App.css";

function App() {
  const [image, setImage] = useState(null);
  const [json, setJson] = useState(null);

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleJsonChange = (event) => {
    setJson(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("image", image);

    try {
      await axios.post("https://czi.everace.io/upload/image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      alert("Files uploaded successfully!");
    } catch (error) {
      console.error("Error uploading files: ", error);
      alert("Error uploading files. Please try again.");
    }
  };

  const handleCompetitionSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("json", json);

    try {
      await axios.post("https://czi.everace.io/upload/json", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      alert("Files uploaded successfully!");
    } catch (error) {
      console.error("Error uploading files: ", error);
      alert("Error uploading files. Please try again.");
    }
  };

  const checkLogin = (event) => {
    // Check if the user is logged in
    // If not, show the login form
    event.preventDefault();
    console.log("event", event.target);
    const username = event.target[0].value;
    const password = event.target[1].value;

    if (username === "antione101" && password === "qwerty123") {
      setIsLoggedIn(true);
    } else {
      alert("Invalid username or password");
    }
  };

  const simpleLogin = () => {
    return (
      <div className="admin-panel">
        <h1 className="admin-panel__title">Login Panel</h1>

        <form onSubmit={checkLogin}>
          <input
            type="text"
            className="admin-panel__label"
            placeholder="username"
          />
          <input
            type="password"
            className="admin-panel__label"
            placeholder="password"
          />
          <button type="submit" className="admin-panel__button">
            Login
          </button>
        </form>
      </div>
    );
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return isLoggedIn ? (
    <div className="admin-panel">
      <h1 className="admin-panel__title">Upload Panel</h1>
      <div className="admin-panel__form-group">
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="image" className="admin-panel__label">
              Upload Image:
            </label>
            <input
              type="file"
              id="image"
              accept="image/*"
              onChange={handleImageChange}
              className="admin-panel__button"
            />
          </div>{" "}
          <br></br>
          <button type="submit" className="admin-panel__button">
            Upload Image One By One:
          </button>
        </form>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <form onSubmit={handleCompetitionSubmit}>
          <div>
            <label htmlFor="json" className="admin-panel__label">
              Upload Single JSON File:
            </label>
            <input
              type="file"
              id="json"
              accept=".json"
              onChange={handleJsonChange}
              className="admin-panel__button"
            />
          </div>
          <br></br>
          <button type="submit" className="admin-panel__button">
            Submit competition.json
          </button>
        </form>

        <br></br>
        <br></br>

        <form onSubmit={handleCompetitionSubmit}>
          <div>
            <label htmlFor="json" className="admin-panel__label">
              Upload Single JSON File:
            </label>
            <input
              type="file"
              id="json"
              accept=".json"
              onChange={handleJsonChange}
              className="admin-panel__button"
            />
          </div>
          <br></br>
          <button type="submit" className="admin-panel__button">
            Submit cleaning.json
          </button>
        </form>
      </div>
    </div>
  ) : (
    simpleLogin()
  );
}

export default App;
